// This file was generated during build from environment variables.
export const apiEndpoint = "https://prod.galileo-ge2c-api.careevolution.com";
export const oauthClientId = "5j4vd9fui5cn42tdn8d2pr04m5";
export const oauthScopes = ["openid","email","profile"];
export const oauthURLs = {
  "authorize": "https://ge2c-galileo-prod.auth.us-east-1.amazoncognito.com/oauth2/authorize",
  "token": "https://ge2c-galileo-prod.auth.us-east-1.amazoncognito.com/oauth2/token",
  "signout": "https://ge2c-galileo-prod.auth.us-east-1.amazoncognito.com/logout"
};
export const validationsEnabled = true;
